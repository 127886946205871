import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/common/layout';
import SEO from '../../components/common/seo';
import BookCover from '../../components/books/book-cover';
import BookChallenge from '../../components/books/book-challenge';

import styles from '../../styles/books/book.module.css';

import localized from '../../../i18n/localized.json';

function Books({ data, pageContext }) {
  const pageDetails = {
    pageTitle: `${localized[pageContext.locale].book.currentlyReading}: ${localized[pageContext.locale].book.title} | wiewior.dev`,
    seoTitle: `${localized[pageContext.locale].book.currentlyReading}: ${localized[pageContext.locale].book.title} | wiewior.dev`,
    seoDescription: 'books on my virtual shelf',
    seoType: 'website'
  };

  return (
    <Layout>
      <div className={styles.bookShelf}>
        <SEO pageDetails={pageDetails} />
        <header>
          <h1>{localized[pageContext.locale].book.currentlyReading}</h1>
          <BookChallenge />
        </header>
        {
        data.allMdx.edges.length ?
          data.allMdx.edges.map(edge => (
            <BookCover book={edge.node.frontmatter} locale={pageContext.locale} key={edge.node.frontmatter.slug} />
          )) :
          <div className={styles.notFound}>{localized[pageContext.locale].shelf.notFound}</div>
        }
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($locale: String!, $dateFormat: String!) {
    allMdx(filter: {fields: {locale: {eq: $locale}}, frontmatter: {shelf: {eq: "currently-reading"}, draft: {eq: false}}}, sort: {fields: frontmatter___added, order: DESC}) {
      edges {
        node {
          frontmatter {
            authors
            slug
            title
            shelf
            startDate(formatString: $dateFormat)
            endDate(formatString: $dateFormat)
            rating
            cover {
              childImageSharp {
                fixed(jpegQuality: 100, height: 280) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Books;
